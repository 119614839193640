import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { translation } from '../../translation';
import useLoggedStatus from '../../hooks/useLoggedStatus';
import { logOut } from '../../redux/appSlices/auth/authSlice';
import EditComponants from './EditComponants';

const Footer = () => {
  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );
  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );

  const [openEditPopup, setOpenEditPopup] = useState(false)

  const logged = useLoggedStatus();
  const dispatch = useDispatch()

  return (
    <>
      <footer id="footer-start" className={`relative bottom-0 bg-black text-white mt-9 pt-11 pb-28 px-[1.3rem]`} style={collaborator?.branding?.color ? { backgroundColor: `${collaborator.branding.color}` } : {}}>
        {/* <div className='h-8 w-[7.3rem]'>
          <img
            src={
              collaborator?.branding?.logo_footer
                ? process.env.REACT_APP_IMAGE_PATH + collaborator.branding.logo_footer
                : "/assets/img/logo.svg"
            }
            className="object-contain w-full h-full"
            alt="PopCard"
            srcSet=""
            id="footer-start"
          />
        </div> */}

        {/* <p className='mt-[30px] text-lg tracking-[-.2px] leading-5'>{!collaborator?.branding && `${collaborator.first_name} ${translation?.footer?.title?.[lang]}`}</p> */}

        <div className='flex justify-between mt-8 w-full'>
          {/* <a className='flex items-center justify-center border-[0.8px] rounded-md text-sm min-h-[3.1rem] w-[180px] cursor-pointer' href="https://getpopcard.com/order" >{translation?.footer?.button?.[lang]}</a> */}

          {
            logged ?
              <div
                className='flex items-center justify-center border-[0.8px] rounded-md text-sm min-h-[3.1rem] w-full cursor-pointer'
                onClick={() => {
                  dispatch(logOut());
                  const keys = Object.keys(localStorage);
                  keys.forEach((key) => {
                    if (key !== "user_token") {
                      localStorage.removeItem(key);
                    }
                  });
                  localStorage.setItem("logout", "true");
                  window.location.reload();
                }}>
                {translation.loginPage.buttonLogout?.[lang]}
              </div>
              : collaborator?.backoffice_id?._id !== process.env.REACT_APP_NUTRIDROP_ID &&
              <div
                className="flex items-center justify-center border-[0.8px] rounded-md text-sm min-h-[3.1rem] w-full cursor-pointer"
                onClick={() => {
                  setOpenEditPopup(true)
                }}
              >
                {translation?.footer?.loginButton?.[lang]}
              </div>
          }

        </div>
        {/* {!collaborator?.branding && <div className='text-[#5a5856] text-sm text-center mt-20'>
          {translation?.footer?.reserved?.[lang]} © 2022 Popcard.com
        </div>} */}

      </footer>

      {openEditPopup && (
        <EditComponants
          componantId={12}
          setOpenEditPopup={setOpenEditPopup}
        />
      )}
    </>
  )
}

export default Footer